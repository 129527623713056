html.plt-ios.plt-hybrid {
  #ItemEditPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #ItemEditPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#ItemEditPage_Header {
  animation: slide-up 0.4s ease;
}

#ItemEditPage_Content {
  animation: slide-up 0.4s ease;

  .ion-activatable {
    position: relative;
    overflow: hidden;
  }
}
