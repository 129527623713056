#ErrorMessages {
  text-align: center;
  margin-bottom: 10px;

  ion-badge {
    font-size: 1rem;
    padding: 10px;
    padding-top: 8px;
    margin-top: 10px;
    white-space: inherit;
  }
}
