#LogoHeader {
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;

  img {
    width: 73px;
    height: 73px;
    text-align: center;
    margin-top: 10px;
    margin-right: 12px;
    margin-left: 12px;
    cursor: pointer;
  }

  div {
    cursor: pointer;
  }

  div#g {
    font-family: 'Lato-Regular';
    font-size: 38px !important;
    text-transform: uppercase;
    color: var(--ion-color-medium-contrast);
  }
  div#b {
    font-family: 'Lato-Regular';
    font-size: 38px !important;
    text-transform: uppercase;
    font-weight: bold;
    text-align: right;
    color: var(--ion-color-medium-contrast);
  }
}

@media (prefers-color-scheme: light) {
  #LogoHeader {
    div#g,
    div#b {
      color: var(--ion-color-primary); // Example text color for dark theme
    }
  }
}
