#RecoverAccountPage {
  ion-content {
    --background: var(--ion-color-medium);
  }

  .title {
    color: var(--ion-text-color);
    font-weight: bold;
    font-size: 18px;
  }

  ion-button {
    margin-bottom: 18px;
    height: 50px;
  }

  #OtherButtons {
    ion-col {
      padding: 0;
      text-align: center;
    }
    ion-button {
      margin-bottom: 0;
    }
  }

  #CodeInput {
    display: flex;
    justify-content: center;
    input {
      --spinner-display: none;
      height: 60px;
      font-size: 1.4rem;
      text-align: center;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 200px;
      border: solid rgba(var(--ion-color-medium-contrast-rgb), 0.3) 2px;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
  }

  ion-text {
    margin-left: 18px;
    margin-right: 18px;
    line-height: 1.5rem;
    display: block;
    color: rgba(var(--ion-color-medium-contrast-rgb), 0.6);
    margin-bottom: 20px;
  }

  ion-col {
    text-align: center;
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;

    ion-item {
      --background: transparent !important;
      background: transparent !important;

      ion-button {
        position: relative;
        top: 5px;
        width: 60px;
        height: 40px;
        margin: 0;
      }

      ion-icon {
        color: var(--ion-color-light-contrast);
      }
    }
  }

  .back-button {
    --color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    i {
      color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    }
  }
}

@media (prefers-color-scheme: light) {
  #CodeInput {
    input {
      background-color: #fff;
    }
  }
}
