#HelpFooter {
  margin-top: 40px;
  text-align: center;
  // background-color: rgba(var(--ion-color-medium-contrast-rgb), 0.1);
  border-radius: 1px;
  padding: 20px 0;
  // border: solid 1px rgba(var(--ion-color-light-contrast-rgb), 0.2);
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;

  ion-col {
    padding: 0;
    margin: 0;
  }

  .trouble-button,
  .visit-button {
    margin-bottom: 0;
    margin-top: 0;
    --color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    i {
      color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    }
  }
}

@media (prefers-color-scheme: light) {
  #HelpFooter {
    // background-color: rgba(var(--ion-color-medium-rgb), 0.1);
  }
}
