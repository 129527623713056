$fontSize3: 1.1rem;

ion-toast.guest-user-toast {
  font-size: $fontSize3 !important;
  --background: var(--ion-color-medium);
  --color: var(--ion-color-medium-contrast);
}

ion-toast.guest-user-toast::part(container) {
  padding-top: 20px;
  padding-bottom: 10px; 
}

ion-toast.guest-user-toast::part(message) {
  font-style: italic;
  margin-bottom: 20px;
}

ion-toast.guest-user-toast::part(header) {
  margin-bottom: 20px;
  font-weight: bold;
}

ion-toast.guest-user-toast::part(button) {
  background: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
  margin-right: 10px;
  border-radius: 6px;
  font-size: 16px;
  text-transform: capitalize;
  cursor: pointer;
}

