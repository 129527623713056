html.plt-ios.plt-hybrid {
  #BoardInvitationNewPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #BoardInvitationNewPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#BoardInvitationNewPage_Content {
  ion-text {
    margin: 20px;
    display: block;
  }

  ion-title {
    padding-left: 0;
    margin-bottom: 20px;
  }

  ion-card-title {
    font-size: 1.5em;
    font-weight: bold;
  }

  ion-card-content {
    background: rgba(var(--ion-color-medium-contrast-rgb), 0.02);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ion-item-divider {
    border-color: rgba(var(--ion-color-light-contrast-rgb), 0.1);
  }

  ion-text {
    line-height: 1.6rem;
  }

  .or {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
  }

  #InvitationCode {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    div#Code {
      border: 1px solid rgba(var(--ion-color-medium-contrast-rgb), 0.2);
      background-color: rgba(var(--ion-color-light-contrast-rgb), 0.1);
      border-radius: 5px;
      padding: 10px;
      padding-bottom: 8px;
      padding-left: 30px;
      font-weight: bold;
      font-size: 1.5em;

      ion-button {
        margin-top: -14px;
        margin-bottom: 0;
      }
    }
  }

  #buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;
    padding-left: 30px;
    padding-right: 30px;

    ion-item {
      --background: transparent !important;
      background: transparent !important;
    }
  }
}
