#WelcomeHeader {
  ion-text.looks-new {
    margin-left: 18px;
    font-weight: bold;
    font-size: 18px;
    display: block;
    padding-bottom: 6px;
  }

  ion-text.for-google, ion-text.for-apple {
    font-size: 0.9rem;
    margin-top: 16px;
    line-height: 1rem;
    margin-left: 18px;

    img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  ion-text.confirm-info {
    margin-top: 26px;
    margin-left: 18px;
    font-size: 0.9rem;
  }

  div.spinner {
    text-align: center;
  }
}
