#RegisterPage {
  ion-content {
    --background: var(--ion-color-medium) !important;
  }

  ion-button {
    margin-bottom: 18px;
    height: 50px;
  }

  #InviteCard {
    margin-bottom: -20px;
    #AcceptBoardInvitation {
      margin-bottom: 40px;
    }
  }

  .or {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 38px;
    display: block;
    font-size: 1.3rem;
  }

  #HaveAccountFooter {
    margin-top: 50px;
    ion-button {
      margin-top: 10px;
    }
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;

    ion-item {
      --background: transparent !important;
      background: transparent !important;

      ion-button {
        position: relative;
        top: 5px;
        width: 60px;
        height: 40px;
        margin: 0;
      }

      ion-icon {
        color: var(--ion-color-light-contrast);
      }
    }
  }
}
