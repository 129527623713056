#MobileAppsPage {
  animation: slide-up 0.4s ease-in;
  --padding-top: 15px;
  --padding-start: 10px;
  --padding-end: 10px;

  #LinksContainer {
    text-align: center;
    padding-top: 20px;

    #QRCode {
      margin: 20px;
      height: 180px;
    }

    #PlayStoreButton,
    #AppStoreButton {
      margin: 10px;
      height: 70px;
    }

    #MSStoreButton {
      margin: 10px;
      height: 80px;
    }
  }
}
