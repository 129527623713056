#RegisterModal {
  #buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 20px;
  }

  ion-item {
    ion-button {
      position: relative;
      top: 5px;
      width: 60px;
      height: 40px;
    }
    ion-icon {
      color: var(--ion-color-light-contrast);
    }
  }
}
