ion-toast.success-toast {
  font-size: 1.2rem !important;
  --background: var(--ion-color-medium);
  --color: var(--ion-color-medium-contrast);
}

html.plt-ios.plt-hybrid {
  #GuestUserPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #GuestUserPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#GuestUserPage_Header {
  animation: slide-up 0.4s ease-in;
}

#GuestUserPage_Content {
  animation: slide-up 0.4s ease-in;
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;

  ion-button {
    margin-bottom: 18px;
  }

  .body {
    margin: auto;
    max-width: 700px;
    border: solid 1px rgba(var(--ion-color-medium-contrast-rgb), 0.1);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
  }

  .google-button,
  .apple-button,
  .email-button,
  .login-button {
    // max-width: 400px;
    // margin: auto;
    // margin-top: 20px;

    img {
      width: 20px;
      height: 20px;
    }

    i,
    ion-icon {
      color: var(--ion-color-tertiary-contrast) !important;
    }
  }

  .login-button,
  .email-button {
    --background: rgb(var(--ion-color-medium-rgb), 1) !important;
    color: var(--ion-color-medium-contrast) !important;
    i,
    ion-icon {
      color: var(--ion-color-medium-contrast) !important;
    }
  }

  .or {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 38px;
    font-size: 1rem;
  }

  .welcome-message {
    padding-left: 0;
  }

  .encouragement,
  .already-registered {
    margin: 20px 0;
    display: block;
    // text-align: center;
  }

  .already-registered {
    border-top: solid 1px rgba(var(--ion-color-light-contrast-rgb), 0.2);
    padding-top: 20px;
    margin-top: 40px;
  }
}
