#InviteCard {
  justify-self: center;

  #InvitationCodeInput {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 220px;
    justify-self: center;
    display: flex;

    ion-input {
      text-align: center;
      border: 1px solid rgba(var(--ion-color-medium-contrast-rgb), 0.2);
      background-color: rgba(var(--ion-color-light-contrast-rgb), 0.1);
      border-radius: 5px;
      font-weight: bold;
      font-size: 1.5em;
      margin-left: 10px;
      height: 50px;
    }

    #Buttons {
      z-index: 100;
      margin-top: -6px;
    }

    ion-button {
      --padding-start: 8px;
      --padding-end: 8px;
      color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
    }
  }

  .have-invite-code-button {
    margin-bottom: 10px;
    --color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    i {
      color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    }
  }

  #AcceptBoardInvitation {
    ion-checkbox {
      width: 26px;
      height: 26px;
    }
    ion-label {
      padding-left: 10px;
    }
  }
}
