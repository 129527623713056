html.plt-ios.plt-hybrid {
  #MealEditPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #MealEditPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#MealEditPage_Content {
  animation: slide-up 0.4s ease;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  .save-button-container {
    text-align: right;
    padding: 10px;
  }

  div.btn-container {
    z-index: 1000 !important;
    background: var(--ion-color-light);
    height: 45px;
    display: flex;
    flex-direction: row;

    div.btn {
      cursor: pointer;
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 5px;
      border: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 2px;
      padding-left: 10px;
      padding-right: 14px;
      padding-top: 16px;
      padding-bottom: 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      min-width: 100px;

      ion-icon {
        margin-top: 2px;
        margin-left: -2px;
        color: var(--ion-color-tertiary) !important;
      }

      div {
        margin-left: 6px;
        color: rgba(var(--ion-color-board-contrast-rgb), 0.8);
      }
    }
  }
}

#MealEditPage_Header {
  animation: slide-up 0.4s ease;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  
  ion-toolbar {
    // --min-height: 46px;
  }
}
