#ForgotPasswordPage {
  ion-content {
    --background: var(--ion-color-medium) !important;
  }

  .back-button {
    --color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    i {
      color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    }
  }

  ion-button{
    margin-bottom: 18px;
    height: 50px;
  }

  #OtherButtons {
    ion-col {
      padding: 0;
      text-align: center;
    }
    ion-button {
      margin-bottom: 0;
    }
  }

  .error {
    display: flex;
    justify-content: center;
  }

  .title {
    color: var(--ion-text-color);
    font-weight: bold;
    font-size: 18px;
  }

  ion-text {
    margin-left: 18px;
    margin-right: 18px;
    line-height: 1.5rem;
    display: block;
    color: rgba(var(--ion-color-medium-contrast-rgb), 0.6);
    margin-bottom: 20px;  
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;

    ion-item {
      --background: transparent !important;
      background: transparent !important;
    }
  }
}
