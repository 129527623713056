html.plt-ios.plt-hybrid {
  #RecipeItemEditModal {
    ion-toolbar:first-child {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

#RecipeItemEditModal {
  ion-content {
    padding: 0;

    ion-grid {
      padding: 0;
      margin-top: 14px;
    }
    ion-row {
      padding: 0;
    }
    ion-col {
      padding: 0;
    }
  }

  .description-input {
    font-weight: bold;
  }

  .items-list {
    margin-top: 10px;
    padding: 0;
  }

  #ItemSelectPage_Content {
    padding-top: 6px;
  }

  div.btn-container {
    z-index: 1000 !important;
    background: var(--ion-color-light);
    cursor: pointer;
    height: 45px;
    display: flex;
    flex-direction: row;

    div.btn {
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 5px;
      border: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 2px;
      padding-left: 10px;
      padding-right: 14px;
      padding-top: 16px;
      padding-bottom: 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      min-width: 100px;

      ion-icon {
        margin-top: 2px;
        margin-left: -2px;
        color: var(--ion-color-tertiary) !important;
      }

      div {
        margin-left: 6px;
        color: rgba(var(--ion-color-board-contrast-rgb), 0.8);
      }
    }
  }
}
