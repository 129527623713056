#PanelCard {
  padding: 15px;
  margin-bottom: 200px;

  .body {
    background-color: rgba(var(--ion-color-light-rgb), 0.5);
    margin: auto;
    max-width: 700px;
    border: solid 1px rgba(var(--ion-color-medium-contrast-rgb), 0.1);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px; 
  }
}
