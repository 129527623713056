#LoginPage {
  ion-content {
    --background: var(--ion-color-medium) !important;
  }

  ion-spinner {
    --color: var(--ion-color-light-contrast);
  }

  ion-button {
    height: 50px;
  }

  .or {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 0;
    display: block;
    font-size: 1.3rem;
  }

  .sign-in-with-email-button {
    margin-top: 30px;
  }

  .forgot-password-button {
    --color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    i {
      color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    }
  }

  #ExternalLogins {
    margin-bottom: 0;

    ion-col {
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0;
    }
  }

  ion-grid#LoginForm {
    background-color: rgba(var(--ion-color-light-contrast-rgb), 0.1);
    border-radius: 6px;
    padding-top: 20px;
    margin: 20px;
    margin-top: 10px;

    ion-button {
      margin-bottom: 8px;
      padding-right: 14px;
    }

    ion-item {
      --background: transparent !important;
      background: transparent !important;
      border-color: transparent !important;

      ion-label {
        margin-top: -10px;
      }

      ion-input {
        border: 1px solid rgba(var(--ion-color-medium-contrast-rgb), 0.2);
        background-color: rgba(var(--ion-color-light-contrast-rgb), 0.1);
        border-radius: 5px;
        padding: 10px;
        padding-bottom: 8px;
        padding-left: 30px;

        input {
          padding: 10px;
        }
      }

      ion-button {
        cursor: pointer;
        position: absolute;
        right: 16px;
        --padding-end: 20px;
        padding-right: 0;
        width: 60px;
        height: 40px;
        margin: 0;
        z-index: 100;
        margin-bottom: 0;
      }

      ion-icon {
        color: var(--ion-color-light-contrast);
      }
    }
  }

}
