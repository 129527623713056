#NativeButton {
  // position: fixed;
  // bottom: 0;
  // left: 50%;
  // transform: translateX(-50%);
  // margin-top: -60px;
  // margin-bottom: 50px;
  ion-col {
    display: flex;
    justify-content: center;
    &.bothButtons {
      &#PlayStoreButton {
        justify-content: flex-end;
      }
      &#AppStoreButton {
        justify-content: flex-start;
      }
    }
  }

  img {
    cursor: pointer;
    margin: 4px;
    max-height: 50px;
  }
}
