#ResetPasswordPage {
  ion-content {
    --background: var(--ion-color-medium);
  }

  ion-button {
    margin-bottom: 18px;
    height: 50px;
  }

  #OtherButtons {
    ion-col {
      padding: 0;
      text-align: center;
    }
    
    ion-button {
      margin-bottom: 0;
    }
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;

    ion-item {
      --background: transparent !important;
      background: transparent !important;

      ion-button {
        position: relative;
        top: 5px;
        width: 60px;
        height: 40px;
        margin: 0;
      }

      ion-icon {
        color: var(--ion-color-light-contrast);
      }
    }
  }

  .back-button {
    --color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    i {
      color: rgba(var(--ion-color-medium-contrast-rgb), 0.4);
    }
  }
}
